import axios from "axios";
import config from '../config.json';
import { isEmpty, getEnvironmentVariables } from "./utils";
import { doRefreshToken } from "../store/reducers/accountService";

let axiosInterceptor = null; // outer variable

export const getApi = (base, token, refreshToken, responseConfig) => {

    const instance = axios.create(
        {
            ...{
                baseURL: base
            }
        });

    if (!!axiosInterceptor || axiosInterceptor === 0) {
        instance.interceptors.request.eject(axiosInterceptor);
    }

    let tokenHolder = token;

    // Add a request interceptor to handle headers
    axiosInterceptor = instance.interceptors.request.use(
        config => {
            //config.headers = headers;
            if (tokenHolder) {
                config.headers['Authorization'] = 'Bearer ' + tokenHolder;
            }
            config.headers['Content-Type'] = 'application/json;charset=UTF-8';
            return config;
        },
        error => {
            Promise.reject(error)
        });

    //Add a response interceptor to handle refresh token behavior
    instance.interceptors.response.use((response) => {
        return response
    }, async function (error) {
        const originalRequest = error.config;

        if (isEmpty(error.response)) {
            return Promise.reject(error);
        }

        if ((error.response.status === 401 || error.response.status === 403) &&
            (originalRequest.url.includes(config.accounts.refreshTokenUrl) ||
                originalRequest.url.includes(config.loginUrl) ||
                (error.response?.data?.code === 'InvalidCredentials' ||
                    error.response?.data?.code === 'UserDisabled' ||
                    error.response?.data?.code === 'LoggedInButUserNotFound'))) {
            //find a way for this: router.push('/login');
            //perhaps dispatch(loginExpired(response.data))? and then we push to router
            return Promise.reject(error);
        }

        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;
            return await instance.request({
                baseURL: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
                url: config.accounts.refreshTokenUrl,
                method: 'POST',
                data: {
                    'accessToken': tokenHolder,
                    'refreshToken': refreshToken,
                    'tenantId': getEnvironmentVariables().REACT_APP_PELLEREX_TENANT_ID
                },
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': 'Bearer ' + tokenHolder
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        //dispatch to update state with new tokens => this could move out to the middleware - optional
                        // dispatch(doRefreshToken(response.data.user));

                        tokenHolder = response.data.user.accessToken;
                        return instance(originalRequest);
                    }
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
        return Promise.reject(error);
    });

    return instance;
}