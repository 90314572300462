import React, { Suspense, Component, lazy } from "react";
import returnStoreAndPersistor from "./store/store";
import { Provider } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";
import history from "./common/history";
import Spinner from "./components/Spinner/Spinner";
import { PersistGate } from "redux-persist/integration/react";
import styles from "./App.module.scss";
//Instrumentation (below two)
import { getAppInsights } from "./services/monitoring/telemetryService";
import TelemetryProvider from "./services/monitoring/telemtryProvider";
import { getEnvironmentVariables } from "./common/utils";
import { Chat } from "./common/conversion/chat";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Routes } from "./common/constants";
import { getAppLanding } from "./common/routing";
import { CompatRouter } from "react-router-dom-v5-compat";
import { PageLoader } from "./components/Spinner/PageLoader";

const PaymentAuthorisationRedirect = lazy(() =>
  import(
    "./screens/Marketplace/Licensing/Account/Payment/Card/PaymentAuthorisationRedirect"
  )
);

const { store, persistor } = returnStoreAndPersistor();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  appInsights = null;

  render() {
    const AppLandingBasedOnUrl = getAppLanding();

    return (
      <div className={styles.App}>
        <React.StrictMode>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router history={history}>
                <CompatRouter>
                  <TelemetryProvider
                    instrumentationKey={
                      getEnvironmentVariables().REACT_APP_INSTRUMENTATION_KEY
                    }
                    after={() => {
                      this.appInsights = getAppInsights();
                    }}
                  >
                    <Suspense fallback={<PageLoader />}>
                      <Chat />
                      <Switch>
                        <Route
                          exact
                          path={
                            Routes.Marketplace.Pages.Conversion.Licensing
                              .PaymentAuthorisationRedirect
                          }
                          component={PaymentAuthorisationRedirect}
                        />
                        <Route>
                          <AppLandingBasedOnUrl />
                        </Route>
                      </Switch>
                    </Suspense>
                  </TelemetryProvider>
                  <Spinner />
                </CompatRouter>
              </Router>
            </PersistGate>
          </Provider>
        </React.StrictMode>
      </div>
    );
  }
}

export default App;
