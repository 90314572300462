import {
    createSlice
} from "@reduxjs/toolkit";
import {
    apiCallBegan,
    customerSignoutCompleted
} from "../middleware/actionUtils";
import config from "../../config.json";
import {
    getEnvironmentVariables
} from '../../common/utils';

export const updateProfile = (profile, accessToken, refreshToken) => {
    let headers = {
        'TenantId': getEnvironmentVariables().REACT_APP_PELLEREX_TENANT_ID
    }
    var action = apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: config.accounts.updateProfileUrl,
        method: "put",
        token: accessToken,
        refreshToken: refreshToken,
        headers,
        data: profile,
        onStart: dataOperationRequested.type,
        onSuccess: updateProfileSucceeded.type,
        onError: dataOperationFailed.type,
    });

    return action;
};

export const saveToken = (user) => {
    return {
        type: 'accountReducer/loginSucceeded',
        payload: {
            user
        }
    }
};

export const refreshProfileDetails = (profileData) => {
    return {
        type: "accountReducer/refreshProfileDetailsSucceeded",
        payload: profileData
    };
};

export const externalLogin = (tokenDetails) => {
    return {
        type: "accountReducer/externalLoginSucceeded",
        payload: tokenDetails
    };
};

export const logout = ({
    accessToken,
    refreshToken
}) => {
    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: config.accounts.logoutUrl,
        method: "post",
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        accessToken,
        refreshToken,
        onStart: dataOperationRequested.type,
        onSuccess: customerSignoutCompleted.type,
        onError: customerSignoutCompleted.type
    });
};

export const activateEmail = (args) => {
    let url = `${config.accounts.emailActivationResponseUrl}?id=${args.id}&activationToken=${args.activationToken}&tenantId=${args.tenantId}`;

    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: url,
        method: "post",
        onStart: dataOperationRequested.type,
        onSuccess: emailActivationSucceeded.type,
        onError: emailActivationFailed.type,
    });
};

export const doRefreshToken = (user) => {
    return {
        type: accessTokenExpired.type,
        payload: user
    };
};

export const doRefreshUserInformation = (accessToken, refreshToken) => {
    return apiCallBegan({
        baseUrl: getEnvironmentVariables().REACT_APP_IDENTITY_API_URL,
        url: config.accounts.fetchMyProfile,
        method: "Get",
        data: {},
        token: accessToken,
        refreshToken: refreshToken,
        onStart: dataOperationRequested.type,
        onSuccess: refreshUserInformationSucceeded.type,
        onError: dataOperationFailed.type
    });
};

//Reducers

const slice = createSlice({
    name: "accountReducer",
    initialState: {
        membership: {
            user: {},
        },
    },
    reducers: {
        dataOperationRequested: (accounts, action) => {},
        //Login
        loginSucceeded: (accounts, action) => {
            accounts.membership = {
                ...accounts.membership,
                ...action.payload
            }
            return accounts;
        },
        externalLoginSucceeded: (accounts, action) => {
            accounts.membership = {
                ...accounts.membership,
                ...action.payload
            }
            return accounts;
        },
        //Profile
        refreshProfileDetailsSucceeded: (accounts, action) => {
            accounts = {
                ...accounts,
                membership: {
                    ...accounts.membership,
                    user: {
                        ...accounts.membership.user,
                        firstName: action.payload.firstName,
                        lastName: action.payload.lastName,
                        phone: action.payload.phone,
                        organisation: action.payload.organisation,
                        brief: action.payload.brief
                    }
                }
            }

            return accounts;
        },
        emailActivationSucceeded: (accounts, action) => {
            accounts = {
                ...accounts,
                membership: {
                    ...accounts.membership,
                    user: {
                        ...accounts.membership.user,
                        isEmailActivated: action.payload.activated
                    }
                },
            };
            return accounts;
        },
        emailActivationFailed: (accounts, action) => {
            accounts = {
                ...accounts,
            };
            return accounts;
        },
        passwordResetLinkRequested: (accounts, action) => {
            accounts.membership.user.passwordResetLinkSent = false;
        },
        passwordResetLinkSent: (accounts, action) => {
            accounts.membership.user.passwordResetLinkSent = true;
        },
        resendPasswordResetEmailRequested: (accounts, action) => {
            accounts.membership.user.passwordResetLinkSent = false;
        },
        resetPasswordRequested: (accounts, action) => {
            accounts.membership.user.changePasswordSucceeded = false;
        },
        resetPasswordSucceeded: (accounts, action) => {
            accounts.membership.user.changePasswordSucceeded = true;
        },
        resetPasswordFailed: (accounts, action) => {},
        accessTokenExpired: (accounts, action) => {
            accounts = {
                ...accounts,
                lastFetched: Date.now(),
                membership: {
                    ...accounts.membership,
                    user: {
                        ...accounts.membership.user,
                        accessToken: action.payload.accessToken,
                        refreshToken: action.payload.refreshToken
                    }
                }
            };
            return accounts;
        },
        updateProfileSucceeded: (accounts, action) => {
            accounts.membership.user = {
                ...accounts.membership.user,
                ...action.payload
            };
            accounts.membership.user.uniqueHandle = action.payload.uniqueHandle;
        },
        refreshUserInformationSucceeded: (accounts, action) => {
            accounts.membership = {
                ...accounts.membership,
                user: {
                    ...action.payload,
                    accessToken: accounts.membership.user.accessToken,
                    refreshToken: accounts.membership.user.refreshToken,
                }
            };
        },
        dataOperationFailed: (accounts, action) => {}
    }
});
export const {
    dataOperationRequested,
    //General Membership
    loginSucceeded,
    signupSucceeded,
    refreshUserInformationSucceeded,
    signupWithAPlanSucceeded,
    emailActivationSucceeded,
    emailActivationFailed,
    accessTokenExpired,
    passwordResetLinkRequested,
    passwordResetLinkSent,
    resendPasswordResetEmailRequested,
    resetPasswordRequested,
    resetPasswordSucceeded,
    resetPasswordFailed,
    //4.0 Navigation
    stepBackRequested,
    //5.0 Profile
    updateProfileSucceeded,
    getCurrentUserActiveSubscriptionSucceeded,
    dataOperationFailed,
} = slice.actions;
export default slice.reducer;