import * as React from "react";
import { Routes } from "../../../common/routing";
import Socials from "./Socials";


export const Footer = (props) => {
  const footerNavigation = {
    solutions: [
      { name: "Digital Transformation", href: "#" },
      { name: "Backend as a service", href: "#" },
    ],
    support: [
      { name: "Pricing", href: Routes.Website.Pages.Conversion.Prices },
      { name: "Documentation", href: Routes.Website.Pages.Docs.Base },
    ],
    company: [
      { name: "Blog", href: Routes.Website.Pages.Blogs.Directory },
      { name: "Latest updates", href: Routes.Website.Pages.Latest.Home },
    ],
    legal: [
      { name: "Privacy", href: Routes.Website.Pages.Others.Policies },
      { name: "Terms", href: Routes.Website.Pages.Others.Terms },
    ]
  };

  return (
    <footer className="mt-20 lg:mt-28" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <img
              className="h-7"
              src="https://cdn.pellerex.com/public/ecosystem/web/home/pellerex-logo.png"
              alt="Pellerex Pty. Ltd."
            />
            <p className="text-sm leading-6 text-gray-600">
              Start building cloudnative enterprise solutions with our one-click Platform as a Service (PaaS).
            </p>
            <Socials />
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Solutions
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Support
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Company
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Legal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-500">
            &copy; {new Date().getFullYear()} Pellerex Pty Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
