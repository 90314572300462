import React, { lazy, Component } from "react";
import WebsiteRouter from "./WebsiteRouter";
import WithAuth from "../components/Auth/WithAuth";
import PellerexNotifications from "../components/Notification/PellerexNotifications";
import MarketingNavBar from "../screens/Website/Common/MarketingNavBar";
import { Footer } from "../screens/Website/Common/Footer";
import { CriticalAlert } from "../components/Annoucements/CriticalAlert";

class Landing extends Component {
  render() {
    return (
      <div className="flex flex-col">
        {/* <CriticalAlert title="Major Upgrade" text="We are deploying major improvements and frontend services might be impacted. All backend service work as usual. If you face any issues, contact support." /> */}
        <MarketingNavBar />
        <WebsiteRouter />
        <PellerexNotifications />
        <Footer />
      </div>
    );
  }
}

export default WithAuth(Landing);
